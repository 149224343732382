import { MediaData } from '@innedit/innedit';
import { DocumentType, MediaType } from '@innedit/innedit-type';
import { PageProps } from 'gatsby';
import React, { FC, SyntheticEvent, useEffect, useRef, useState } from 'react';

import Item from '../../../../components/List/Item/Media';
import CMSView from '../../../../components/View';
import CMSList from '../../../../components/View/List';
import IconAdd from '../../../../icons/Add';
import TemplateEspace from '../../../../templates/espace';
import requireEspace, { EspaceProps } from '../../../../utils/requireEspace';
import { UserProps } from '../../../../utils/requireUser';

const PageMedias: FC<PageProps & EspaceProps & UserProps> = ({
  location,
  espace,
  user,
  params: { espaceId },
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [medias, setMedias] = useState<DocumentType<MediaType>[]>();
  const [nbParLigne] = useState<number>(5);

  useEffect(() => {
    const mediaData = new MediaData({ espaceId });
    const unsub = mediaData.watch(newDocs => {
      setMedias(newDocs);
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [espaceId]);

  const handleOnChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const mediaData = new MediaData({ espaceId });

    if (e.currentTarget && e.currentTarget.files) {
      const { files } = e.currentTarget;
      const promises = [];
      for (let i = 0; i < files.length; i += 1) {
        const image = files.item(i);
        if (image) {
          promises.push(mediaData.uploadFile(image));
        }
      }

      Promise.all(promises)
        .then(values =>
          values.map(document => {
            if (!document) {
              console.error("Le document n'existe pas");

              return false;
            }

            return true;
          }),
        )
        .catch(error => {
          // TODO afficher le message d'erreur -> Mettre en place un système de notification
          console.error(error.code, error.message);
        });
    }
  };

  // const handleOnChangeNbParLigne = (e: SyntheticEvent<HTMLInputElement>) => {
  //   setNbParLigne(parseInt(e.currentTarget.value, 10));
  // };

  // <ActionBar className="action-bar is-clearfix">
  //   <ul>
  //     <li className="navbar-item">
  //       <ButtonAddMedia boutique={boutique} />
  //     </li>
  //   </ul>
  //
  //   <ul className="border-left navbar-right">
  //     <li className="navbar-item nbByRow">
  //       <input
  //         onChange={handleOnChangeNbParLigne}
  //         type="number"
  //         value={nbParLigne}
  //       />
  //     </li>
  //   </ul>
  // </ActionBar>

  const handleUploadNewMedias = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  };

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView className="flex flex-col md:px-12 mx-auto my-6">
        <CMSList
          menu={{
            left: [],
            right: [
              {
                iconRight: IconAdd,
                onClick: handleUploadNewMedias,
                text: 'Importer un média',
                variant: 'solid',
              },
            ],
          }}
          search={location.search}
          title="Médias"
          user={user}
        >
          <input
            ref={inputRef}
            multiple
            onChange={handleOnChange}
            style={{ display: 'none' }}
            type="file"
          />
          {medias && medias.length > 0 && (
            <ol className={`mx-6 py-6 grid gap-6 grid-cols-${nbParLigne}`}>
              {medias.map((media, index) => (
                <Item key={media.id} doc={media} index={index} />
              ))}
            </ol>
          )}
        </CMSList>
      </CMSView>
    </TemplateEspace>
  );
};
export default requireEspace(PageMedias);
